import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  logoWrapper: {
    paddingTop: '5px',
    textDecoration: 'none',
  },
}))
// 220 800 154
const SiteLogo = (props) => {
  const classes = useStyles()
  const { siteLogo } = useStaticQuery(graphql`
    query {
      siteLogo: file(relativePath: { eq: "logo/erezlogobig64.png" }) {
        childImageSharp {
          lg: fixed(width: 220, quality: 100) {
            src
          }
          small: fixed(width: 154, toFormat: WEBP, quality: 80) {
            src
          }
        }
      }
    }
  `)
  return (
    <Link to='/' {...props}>
      <div className={classes.logoWrapper}>
        <picture>
          <source type='image/webp' media='(max-width:759px)' srcSet={siteLogo.childImageSharp.small.src} />
          <img
            // className={classes.root}
            loading='lazy'
            src={siteLogo.childImageSharp.lg.src}
            alt={'Erez Kaynak Logo'}
          />
        </picture>
      </div>
    </Link>
  )
}
export default SiteLogo
