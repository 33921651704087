import React, { useState, useRef, useEffect } from 'react'
import { globalHistory } from '@reach/router'
import Container from '@material-ui/core/Container'
import { makeStyles } from '@material-ui/core/styles'
import Navbar from './Navbar'
import Sidebar from './Sidebar'
// import Footer from './Footer'
import PageFooter from './Footer/PageFooter'
const sideBarWidth = 280
// const xlSidebar = 70
const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    height: 'calc( 100vh - 56px )',

    overflowY: 'auto',
    // backgroundColor: '#f1f1f1',
    [theme.breakpoints.up('sm')]: {
      height: 'calc( 100vh - 64px )',
    },
    [theme.breakpoints.up('md')]: {
      height: 'calc( 100vh - 74px )',
      // width: `${sideBarWidth}px`,
      flexShrink: 0,
    },
  },

  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: `${sideBarWidth}px`,

    // [theme.breakpoints.up('xl')]: {
    //   width: `${sideBarWidth + xlSidebar}px`,
    // },
  },
  content: {
    flexGrow: 1,
    // backgroundColor: '#f7f7f7',
    backgroundColor: theme.palette.grey[900],
    height: 'calc( 100vh - 56px )',
    overflowY: 'auto',
    [theme.breakpoints.up('sm')]: {
      height: 'calc( 100vh - 64px )',
    },
    [theme.breakpoints.up('md')]: {
      height: 'calc( 100vh - 74px )',
    },
  },
}))
const MainContainer = ({ children }) => {
  const [sideOpen, setSideOpen] = useState(false)
  const mainRef = useRef()
  const classes = useStyles()

  const toggleSideBar = () => {
    setSideOpen(!sideOpen)
  }
  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH') {
        mainRef.current.scrollTop = 0
      }
    })
  }, [])
  return (
    <>
      <Navbar toggle={toggleSideBar} />
      <Container maxWidth='xl' disableGutters={true} className={classes.root}>
        <nav className={classes.drawer} aria-label='menüler'>
          <Sidebar drawerWidth={sideBarWidth} sideOpen={sideOpen} toggle={toggleSideBar} />
        </nav>
        <main ref={mainRef} className={classes.content} id='main-container'>
          {children}
          <PageFooter />
          {/* <Footer /> */}
        </main>
      </Container>
    </>
  )
}
export default MainContainer
