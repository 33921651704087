import React, { useState } from 'react'
import { Link } from 'gatsby'
import Button from '@material-ui/core/Button'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  navButton: {
    color: theme.palette.grey[400],
    '& :hover': {
      color: theme.palette.grey[200],
      '& .MuiButton-startIcon': {
        color: theme.palette.secondary.light,
      },
    },
    '& .MuiButton-startIcon': {
      '& :hover': { color: theme.palette.secondary.light },
      marginRight: '4px',
      // color: theme.palette.grey.A700,
      color: theme.palette.secondary.main,
    },
    '& svg': {
      height: '1.1rem',
      width: '1.1rem',
      [theme.breakpoints.up('xl')]: {
        height: '1.6rem',
        width: '1.6rem',
      },
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '1.5em',
    },
  },
  menu: {
    '& .MuiMenu-paper': {
      backgroundColor: '#252525',
    },
  },
  menuItem: {
    color: 'rgba(255,255,255,.7)',
    transition: 'all ease 0.5s',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}))
const DropdownNavButton = (props) => {
  const classes = useStyles()
  const { title, icon, children } = props.data
  const [anchorEl, setAnchorEl] = useState(null)
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  return (
    <>
      <Button
        className={classes.navButton}
        aria-controls='simple-menu'
        startIcon={icon}
        color='default'
        aria-label={title}
        aria-haspopup='true'
        onClick={handleClick}
      >
        {title}
      </Button>
      <Menu
        id='simple-menu'
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        className={classes.menu}
      >
        {children.map((item, index) => (
          <MenuItem component={Link} to={item.url} className={classes.menuItem} onClick={handleClose} key={index}>
            {item.title}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}
export default DropdownNavButton
