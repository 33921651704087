import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import Container from '@material-ui/core/Container'
import IconButton from '@material-ui/core/IconButton'
import Toolbar from '@material-ui/core/Toolbar'
import MenuIcon from '@material-ui/icons/Menu'
import { makeStyles } from '@material-ui/core/styles'
import { navData } from './navData'
import NavButton from './NavButton'

import SiteLogo from './SiteLogo'
import DropdownNavButton from './DropdownNavButton'

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 10,
    backgroundColor: theme.palette.grey[900],
    // backgroundColor: '#252525',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  leftSection: {
    display: 'inline-block',
    padding: '5px 0',
    // [theme.breakpoints.up('md')]: {
    //   width: '0',
    //   visibility: 'hidden',
    // },
  },
  rightSection: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  menuButton: {
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}))

const Navbar = ({ toggle }) => {
  const classes = useStyles()

  return (
    <AppBar position='sticky' color='primary' elevation={4} className={classes.root}>
      <Container maxWidth='xl' disableGutters>
        <Toolbar className={classes.toolbar}>
          <div className={classes.leftSection}>
            <SiteLogo />
          </div>
          <div className={classes.rightSection}>
            {navData.map((item, index) => (
              <>
                {item.children ? (
                  <DropdownNavButton data={item} key={`nav-${index}`} />
                ) : (
                  <NavButton title={item.title} url={item.url} icon={item.icon} key={`nav-${index}`} />
                )}
              </>
            ))}
          </div>
          <IconButton color='secondary' aria-label='Menüyü Aç' edge='start' className={classes.menuButton} onClick={toggle}>
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </Container>
    </AppBar>
  )
}
export default Navbar
