import React, { createContext, useReducer } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Reducer from './Reducer'
// const defaultSeo = {
//   title: `Erez Kaynak - Çelik Hasır Tel ve Paslanmaz Filtre İmalatçısı - Hızlı ve Kaliteli Hizmet`,
//   description: `Paslanmaz, galvanize yada çelik telden, tel kalınlığı ve ebat, göz aralığı fark etmeksizin punta kaynaklı hasır tel (çesan) imalatı yapılır.Paslanmaz elek teli ve paslanmaz filtre imalatçısı`,
//   keywords: `hasır tel,hasır tel fiyatları,hasır tel imalatı,paslanmaz hasır tel,hasır telleri,puntalı hasır tel,punta kaynaklı hasır tel,puntalı hasır tel fiyatları,hasır,tel,hasır tel imalatçısı,özel hasır tel,galvaniz hasır tel,hasır tel istanbul,tel hasır,kafes tel,tel hasır fiyatları,kafes tel fiyatları,hasır tel fiyatı,filtre imalatı,elek imalatı,paslanmaz filtre,paslanmaz elek`,
//   author: `Uğur Erkin Demirkaya`,
//   siteUrl: `https://www.paslanmazfiltre.com`,
// }
const initialState = {
  loading: false,
  sbOpen: false,
  theme: {
    primary: '#9575cd',
    primaryDark: '#7e57c2',
    primaryLight: '#b085f5',
    secondary: '#ff9100',
    secondaryDark: '#ff6d00',
    secondaryLight: '#ffc246',
    warn: '#ef5350',
    warnDark: '#f44336',
    warnLight: '#ff867c',
  },
  images: [],
  // seo: {
  //   title: defaultSeo.title,
  //   description: defaultSeo.description,
  //   keywords: defaultSeo.keywords,
  //   author: defaultSeo.author,
  //   siteUrl: defaultSeo.siteUrl,
  //   lang: 'tr',
  //   // robots: { name: 'robots', content: 'index,follow' },
  //   metaUrl: defaultSeo.siteUrl,
  //   metaImgUrl: `${defaultSeo.siteUrl}/icons/icon-512x512.png`,
  // },
}
export const Context = createContext(initialState)
const Store = ({ children }) => {
  const { allImageSharp } = useStaticQuery(
    graphql`
      query {
        allImageSharp {
          nodes {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
            parent {
              ... on File {
                id
                name
                relativePath
                relativeDirectory
              }
            }
            xs: fixed(width: 310, toFormat: WEBP) {
              src
            }

            sm: fixed(width: 450, toFormat: WEBP) {
              src
            }

            md: fixed(width: 760, toFormat: WEBP) {
              src
            }

            lg: fixed(width: 580, toFormat: WEBP) {
              src
            }
            thumb: fixed(width: 360, toFormat: WEBP) {
              src
            }
            original {
              src
            }
          }
        }
      }
    `,
  )

  const [state, dispatch] = useReducer(Reducer, {
    ...initialState,
    images: allImageSharp.nodes,
  })

  return <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>
}
export default Store
