// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-banka-bilgileri-js": () => import("./../../../src/pages/banka-bilgileri.js" /* webpackChunkName: "component---src-pages-banka-bilgileri-js" */),
  "component---src-pages-galeri-js": () => import("./../../../src/pages/galeri.js" /* webpackChunkName: "component---src-pages-galeri-js" */),
  "component---src-pages-galvaniz-celik-hasir-js": () => import("./../../../src/pages/galvaniz-celik-hasir.js" /* webpackChunkName: "component---src-pages-galvaniz-celik-hasir-js" */),
  "component---src-pages-hakkimizda-js": () => import("./../../../src/pages/hakkimizda.js" /* webpackChunkName: "component---src-pages-hakkimizda-js" */),
  "component---src-pages-iletisim-js": () => import("./../../../src/pages/iletisim.js" /* webpackChunkName: "component---src-pages-iletisim-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-paslanmaz-celik-hasir-js": () => import("./../../../src/pages/paslanmaz-celik-hasir.js" /* webpackChunkName: "component---src-pages-paslanmaz-celik-hasir-js" */),
  "component---src-pages-siyah-celik-hasir-js": () => import("./../../../src/pages/siyah-celik-hasir.js" /* webpackChunkName: "component---src-pages-siyah-celik-hasir-js" */)
}

