import React from 'react'
import { Link } from 'gatsby'
import Drawer from '@material-ui/core/Drawer'
import Hidden from '@material-ui/core/Hidden'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import { makeStyles } from '@material-ui/core/styles'

import { navData } from '../Navbar/navData'
import NavLogo from '../Navbar/NavLogo'

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDrawer-paper': {
      backgroundColor: theme.palette.grey[900],
    },
  },
  logoWrapper: {
    padding: '10px 0 20px 0',
    display: 'flex',
    justifyContent: 'center',
  },
  listItem: {
    '& .MuiListItemIcon-root': {
      minWidth: '24px',
      color: theme.palette.error.main,
    },
    '&.MuiListItem-gutters ': {
      paddingLeft: '5px',
      paddingRight: '10px',
    },
  },
  listItemText: {
    color: theme.palette.grey[300],
    '& .MuiListItemText-primary': {
      color: theme.palette.grey[300],
      [theme.breakpoints.up('xl')]: {
        fontSize: '1.5em',
      },
    },
  },
  listItemMobil: {
    color: theme.palette.grey[300],

    '& .MuiListItemIcon-root': {
      minWidth: '24px',
      color: theme.palette.secondary.main,
    },
    '& svg': {
      width: '1.1rem',
      height: 'auto',
    },
    '&.MuiListItem-gutters ': {
      // paddingLeft: '5px',
      // paddingRight: '10px',
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing.apply(3),
    },
  },
}))
const SideBarListMobile = ({ toggleSlider }) => {
  const classes = useStyles()
  return (
    <List dense>
      {navData.map((data, index) => (
        <>
          {data.children ? (
            <>
              <ListItem to={data.url} button key={index} className={classes.listItemMobil}>
                <ListItemIcon>{data.icon}</ListItemIcon>
                <ListItemText primary={data.title} />
              </ListItem>
              {data.children.map((cItem, cIndex) => (
                <ListItem component={Link} to={cItem.url} button key={cIndex} className={classes.listItemMobil} onClick={toggleSlider}>
                  <ListItemIcon>
                    <ChevronRightIcon />
                  </ListItemIcon>
                  <ListItemText primary={cItem.title} />
                </ListItem>
              ))}
            </>
          ) : (
            <ListItem component={Link} to={data.url} button key={index} className={classes.listItemMobil} onClick={toggleSlider}>
              <ListItemIcon>{data.icon}</ListItemIcon>
              <ListItemText primary={data.title} />
            </ListItem>
          )}
        </>
      ))}
    </List>
  )
}

const Sidebar = ({ sideOpen, toggle }) => {
  const classes = useStyles()

  return (
    <>
      <Hidden mdUp implementation='js'>
        <Drawer open={sideOpen} variant='temporary' onClose={toggle} className={classes.root}>
          <div>
            <div className={classes.logoWrapper}>
              <NavLogo />
            </div>
            <SideBarListMobile toggleSlider={toggle} />
          </div>
        </Drawer>
      </Hidden>
    </>
  )
}
export default Sidebar
