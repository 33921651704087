import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  logoWrapper: {
    width: '220px',
    textDecoration: 'none',
    [theme.breakpoints.down('sm')]: {
      width: '154px',
    },
  },
}))
const NavLogo = (props) => {
  const classes = useStyles()
  const { siteLogo } = useStaticQuery(graphql`
    query {
      siteLogo: file(relativePath: { eq: "logo/erezlogobig64.png" }) {
        childImageSharp {
          fluid(maxWidth: 220, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `)
  return (
    <Link to='/' {...props}>
      <div className={classes.logoWrapper}>
        <Img fluid={siteLogo.childImageSharp.fluid} loading='eager' fadeIn={false} />
      </div>
    </Link>
  )
}
export default NavLogo
