import React from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import Store from '../store'
import MainContainer from './MainContainer'
// import ReduxSeo from './ReduxSeo'
const Layout = ({ children }) => {
  return (
    <Store>
      <CssBaseline>
        {/* <ReduxSeo> */}
        <MainContainer>{children}</MainContainer>
        {/* </ReduxSeo> */}
      </CssBaseline>
    </Store>
  )
}
export default Layout
