import React from 'react'
import { Link } from 'gatsby'
import { makeStyles } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'

const useStyles = makeStyles((theme) => ({
  navLink: {
    textDecoration: 'none',
  },
  navButton: {
    color: theme.palette.grey[400],
    '& :hover': {
      color: theme.palette.grey[200],
      '& .MuiButton-startIcon': {
        color: theme.palette.secondary.light,
      },
    },
    '& .MuiButton-startIcon': {
      '& :hover': { color: theme.palette.secondary.light },
      marginRight: '4px',
      // color: theme.palette.grey.A700,
      color: theme.palette.secondary.main,
    },
    '& svg': {
      height: '1.1rem',
      width: '1.1rem',
      [theme.breakpoints.up('xl')]: {
        height: '1.6rem',
        width: '1.6rem',
      },
    },
    [theme.breakpoints.up('xl')]: {
      fontSize: '1.5em',
    },
  },
}))
const NavButton = ({ title, url, icon }) => {
  const classes = useStyles()
  return (
    <Link className={classes.navLink} to={url}>
      <Button className={classes.navButton} startIcon={icon} color='default'>
        {title}
      </Button>
    </Link>
  )
}
export default NavButton
