import React from 'react'
import HomeIcon from '@material-ui/icons/Home'
import PhotoIcon from '@material-ui/icons/Photo'
import ContactSupportIcon from '@material-ui/icons/ContactSupport'
import AccountBalanceIcon from '@material-ui/icons/AccountBalance'
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar'
import AccountTreeIcon from '@material-ui/icons/AccountTree'
export const navData = [
  { title: 'Ana Sayfa', url: '/', icon: <HomeIcon /> },
  {
    title: 'Ürünler',
    icon: <AccountTreeIcon />,
    children: [
      { title: 'Paslanmaz Çelik Hasır', url: '/paslanmaz-celik-hasir' },
      { title: 'Siyah Çelik Hasır', url: '/siyah-celik-hasir' },
      { title: 'Galvaniz Çelik Hasır', url: '/galvaniz-celik-hasir' },
    ],
  },
  { title: 'Ürün Resimleri', url: '/galeri', icon: <PhotoIcon /> },
  { title: 'Hakkımızda', url: '/hakkimizda', icon: <ContactSupportIcon /> },
  { title: 'Banka Bilgileri', url: '/banka-bilgileri', icon: <AccountBalanceIcon /> },
  { title: 'İletişim', url: '/iletisim', icon: <PermContactCalendarIcon /> },
]
