const Reducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_SIDEBAR':
      return { ...state, sbOpen: true }
    case 'CLOSE_SIDEBAR':
      return { ...state, sbOpen: false }
    case 'TOGGLE_SIDEBAR':
      return { ...state, sbOpen: !state.sbOpen }
    // case 'SET_SEO':
    //   return { ...state, seo: { ...state.seo, ...action.payload } }
    default:
      return state
  }
}
export default Reducer
