import { createMuiTheme, colors } from '@material-ui/core'

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      main: colors.amber[800],
    },
    secondary: {
      main: colors.red[400],
    },
    error: {
      main: colors.deepOrange[500],
    },
    background: {
      // default: `#f2f6fa`,
      default: colors.grey[700],
    },
  },
})

export default theme
